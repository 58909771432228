import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import Api from '../../../../../endpoint/api';

export function useArticles(categoryId, page, keyword = '') {
    // const getCategoriesFn = async () => {
    //     const response = await axios.get(`${Api.wp_url}/wp-json/wp/v2/categories`);

    //     return response.data;
    // }

    const getArticlesFn = async () => {
        // const categories = JSON.parse(localStorage.getItem('categories')) || await getCategoriesFn();
        const postResponse = await axios.get(`${Api.wp_url}/wp-json/wp/v2/posts?per_page=10&categories=${categoryId}&page=${page ?? 1}&search=${keyword ?? ''}`);

        // map post with its categories
        const postWithCategories = postResponse.data.map((item) => {
            // let categories_text = item.categories.map((categoryId) => {
            //     return categories.find((category) => category.id === categoryId).name
            // }).join(", ");

            return {
                ...item,
                // categories_text
            }
        });

        return postWithCategories;
    };

    return useQuery({
        queryKey: ['useArticles', categoryId, page, keyword],
        queryFn: getArticlesFn,
        keepPreviousData: true,
    });
}
