import Footer from "../../components/footer";
import AccordionCustom from "../../components/accordion";
import Navbar from "../../components/navbar";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Link } from "react-router-dom";
import {
  EffectCoverflow,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Grid,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import Ellipse from "../../../../assets/images/ellipse-pink.png";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import CardProduct from "../../components/card-product";
import Testimoni1 from "../../../../assets/images/testimoni1.png";
import Testimoni from "../../components/testimoni";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState } from "react";
import { PrimeReactProvider } from "primereact/api";
import { useRecommendations } from "./queries/use-recommendations";
import { useTestimonials } from "./queries/use-testimonials";
import { useCategories } from "./queries/use-categories";
import ArticleList from "./components/article-list";
import CardBayi from "../../components/card-bayi";

import Halal from "../../../../assets/images/halal.png";
import Bpom from "../../../../assets/images/bpom.png";
import TopBrand from "../../../../assets/images/top.png";
import Brand from "../../../../assets/images/brand.png";
import Brand2 from "../../../../assets/images/brand2.png";
import Logo from "../../../../assets/images/logo.svg";

import Bayi1 from "../../../../assets/images/bayi1.png";
import Bayi2 from "../../../../assets/images/bayi2.png";
import Bayi3 from "../../../../assets/images/bayi3.png";
import Bayi4 from "../../../../assets/images/bayi4.png";
import Bayi5 from "../../../../assets/images/bayi5.png";
import Bayi6 from "../../../../assets/images/bayi6.png";

export default function Homepage() {
  const categories = useCategories();
  const recomendations = useRecommendations();
  const testimonials = useTestimonials();
  const [currentTab, setCurrentTab] = useState(0);

  const dummyTabs = [
    { id: 1, name: "Artikel Terbaru" },
    { id: 2, name: "Artikel Populer" },
    { id: 3, name: "Artikel Populer" },
  ];
  const handleSelectTab = (index) => {
    setCurrentTab(index);
  };

  const SwiperButtonNext = () => {
    const swiper = useSwiper();
    return (
      <svg
        onClick={() => swiper.slideNext()}
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.472973"
          y="0.472973"
          width="69.0541"
          height="69.0541"
          rx="34.527"
          fill="white"
        />
        <rect
          x="0.472973"
          y="0.472973"
          width="69.0541"
          height="69.0541"
          rx="34.527"
          stroke="#F8C4C4"
          strokeWidth="0.945946"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.6706 35.8977C47.1992 35.3691 47.1992 34.512 46.6706 33.9834L39.4516 26.7644C38.923 26.2358 38.0659 26.2358 37.5373 26.7644C37.0087 27.293 37.0087 28.15 37.5373 28.6786L42.4457 33.587H24.0563C23.3088 33.587 22.7027 34.193 22.7027 34.9405C22.7027 35.6881 23.3088 36.2941 24.0563 36.2941H42.4457L37.5373 41.2025C37.0087 41.7311 37.0087 42.5881 37.5373 43.1167C38.0659 43.6453 38.923 43.6453 39.4516 43.1167L46.6706 35.8977Z"
          fill="#DE8D8D"
        />
      </svg>
    );
  };

  const SwiperButtonPrev = () => {
    const swiper = useSwiper();

    return (
      <svg
        onClick={() => {
          swiper.slidePrev();
        }}
        width="70"
        height="70"
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-0.472973"
          y="0.472973"
          width="69.0541"
          height="69.0541"
          rx="34.527"
          transform="matrix(-1 0 0 1 69.0541 0)"
          fill="white"
        />
        <rect
          x="-0.472973"
          y="0.472973"
          width="69.0541"
          height="69.0541"
          rx="34.527"
          transform="matrix(-1 0 0 1 69.0541 0)"
          stroke="#F8C4C4"
          strokeWidth="0.945946"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3294 35.8977C22.8008 35.3691 22.8008 34.512 23.3294 33.9834L30.5484 26.7644C31.077 26.2358 31.9341 26.2358 32.4627 26.7644C32.9913 27.293 32.9913 28.15 32.4627 28.6786L27.5543 33.587H45.9437C46.6912 33.587 47.2973 34.193 47.2973 34.9405C47.2973 35.6881 46.6912 36.2941 45.9437 36.2941H27.5543L32.4627 41.2025C32.9913 41.7311 32.9913 42.5881 32.4627 43.1167C31.9341 43.6453 31.077 43.6453 30.5484 43.1167L23.3294 35.8977Z"
          fill="#DE8D8D"
        />
      </svg>
    );
  };

  const [showMaps, setShowMaps] = useState(false);

  function DataTableComponent() {
    const data = [];

    for (let i = 0; i < 10; i++) {
      data.push({
        city: "Kabupaten Barito Selatan",
        instagram: "@hanimamabear",
      });
    }

    return (
      <div className="card-datatable">
        <DataTable
          value={data}
          rows={10}
          // rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ width: "100%" }}
        >
          <Column
            field="city"
            header="Kota/Kabupaten"
            style={{ width: "50%" }}
            sortable
          ></Column>
          <Column
            field="instagram"
            header="Instagram"
            style={{ width: "50%" }}
            sortable
          ></Column>
        </DataTable>
      </div>
    );
  }

  return (
    <PrimeReactProvider>
      <div className="wrapper-pages">
        <Navbar />

        <div className="homepage">
          <div className="hero position-relative">
            {/* <img src="images/article-hero.png" alt="" /> */}
            <Swiper
              className="heroSwiper"
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
              navigation
              pagination={{ clickable: true }}
              modules={[Autoplay]}
              autoplay={{
                delay: 2500, // Waktu delay dalam milidetik
                disableOnInteraction: false,
              }}
            >
              <SwiperSlide>
                <div className="image-slide">
                  <img src="images/article-hero.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image-slide">
                  <img src="images/article-hero2.png" alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="image-slide">
                  <img src="images/article-hero3.png" alt="" />
                </div>
              </SwiperSlide>
              <div className="navigation-carousel">
                <div className="navigation-item">
                  {/* <SwiperButtonPrev />
                  <SwiperButtonNext /> */}
                </div>
              </div>
            </Swiper>
            <div className="navigation">
              <p>Lihat Produk Kami</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.0507 30.3372C17.6095 30.8961 18.5155 30.8961 19.0743 30.3372L26.7059 22.7057C27.2647 22.1469 27.2647 21.2408 26.7059 20.682C26.1471 20.1232 25.2411 20.1232 24.6823 20.682L19.4934 25.8709V6.43069C19.4934 5.64041 18.8528 4.99977 18.0625 4.99977C17.2722 4.99977 16.6316 5.64041 16.6316 6.43069V25.8709L11.4427 20.682C10.8839 20.1232 9.97792 20.1232 9.41911 20.682C8.8603 21.2408 8.8603 22.1469 9.41911 22.7057L17.0507 30.3372Z"
                  fill="#191919"
                />
              </svg>
            </div>
          </div>

          <div className="wrapper">
            <div className="info">
              <div className="container">
                <div className="wrapper">
                  <div className="text-title">
                    <h2>Suplemen Menyusui</h2>
                    <p>Rekomendasi produk kami untuk kebutuhan mama</p>
                  </div>
                  <div className="info-wrapper">
                    <div className="info-item">
                      <img src="images/info1.png" alt="" />
                      <div className="text">
                        <h2>
                          Kualitas <br /> menyeluruh
                        </h2>
                        <p>Bahan baku selektif, Aman untuk Mama</p>
                      </div>
                    </div>
                    <div className="info-item">
                      <img src="images/info2.png" alt="" />
                      <div className="text">
                        <h2>
                          Superfood <br />
                          Mama Rasa Juara
                        </h2>
                        <p>
                          Mengandung Makro-Mikro Nutrisi lengkap untuk membantu
                          Mama mengASIhi
                        </p>
                      </div>
                    </div>
                    <div className="info-item">
                      <img src="images/info3.png" alt="" />
                      <div className="text">
                        <h2>
                          Bersertifikat <br />
                          Halal & BPOM
                        </h2>
                        <p>
                          Sebagai bukti komitmen kualitas pada solusi menyusui,
                          setiap produk MamaBear sudah teruji, tersertifikasi
                          Halal & BPOM.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="latest-article">
              <div className="container">
                <div className="text-title">
                  <h2>Artikel</h2>
                  <p>
                    Setiap harinya ada banyak artikel dan beragam info menarik
                    yang dapat Mama baca.
                  </p>
                </div>
                <Tabs onSelect={handleSelectTab} selectedIndex={currentTab}>
                  <TabList className={"custom-tab-list"}>
                    {dummyTabs.map((tab) => (
                      <Tab
                        key={tab.id}
                        className={`item ${tab.id === 2 ? "d-none" : ""}`}
                      >
                        {tab.name}
                      </Tab>
                    ))}
                  </TabList>

                  {categories?.isSuccess && Array.isArray(categories.data) &&
                    categories?.data?.map((category) => (
                      <TabPanel
                        value={currentTab}
                        key={`tabPanel${category.id}`}
                      >
                        <ArticleList key={category.id} category={category} />
                      </TabPanel>
                    ))}
                </Tabs>
              </div>
            </div>

            <div className="terlaris">
              <div className="wrapp-bg-img">
                <img src="images/bg-produk.png" alt="" />
              </div>
              <div className="wrapp-text">
                {/* <img src="images/petren.png" alt="" /> */}
                <div className="judul">
                  <p className="top">Terlaris</p>
                  <p className="bottom">Teh Pelancar ASI</p>
                </div>
                <div className="desc">
                  <p className="">
                    Terbuat dari bahan herbal pilihan, Teh Pelancar ASI Mamabear
                    telah terbukti membantu produksi ASI pada ibu menyusui.
                    Dengan aroma dan rasa yang nikmat, temukan rahasia ASI
                    berlimpah dari Mamabear.
                  </p>
                </div>

                {/* <div className="btn-beli"> */}
                <Link className="btn-beli" to={"/store/home"}>
                  <p className="m-0">Beli Produk</p>
                </Link>
                {/* </div> */}
              </div>
            </div>

            <div className="product-recomendation">
              <div className="container">
                <div className="wrapper">
                  <div className="text-title">
                    <h2>Apa Yang Kami Tawarkan?</h2>
                    <p>Rekomendasi produk kami untuk kebutuhan mama</p>
                  </div>

                  <div className="card-product-wrapper">
                    {/* <div className="card-product">
                      <img className="product-image" src="images/product-1.png" alt="" />
                      <div className="text">
                        <div className="promo">Bundle Promo</div>
                        <h1>Happiness Package By Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam reiciendis sunt ex officiis pariatur sed officia consequuntur nam obcaecati eveniet explicabo quaerat ad illo esse nulla modi, nemo iusto ipsam.</h1>
                        <hr />
                        <div className="price">Rp 150.000</div>
                      </div>
                      <img src="/svg/product-cart.svg" alt="" />
                    </div> */}

                    {/* Recommendations */}
                    {recomendations.isLoading && (
                      <div className="py-2 text-teal-900 font-bold">
                        Loading...
                      </div>
                    )}

                    {recomendations.isFetching && (
                      <div className="py-2 text-teal-900 font-bold">
                        Fetching...
                      </div>
                    )}

                    {recomendations.error instanceof Error && (
                      <div>{recomendations.error.message}</div>
                    )}

                    {recomendations.isSuccess &&
                      recomendations.data
                        .slice(0, 3)
                        .map((recomendation, index) => (
                          <CardProduct
                            key={index}
                            product={recomendation.product}
                            price={recomendation.product.price_text}
                            image={recomendation.product.image_url_text}
                            title={recomendation.product.name}
                            promo={recomendation.category.name}
                          />
                        ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="keunggulan">
              <div className="container">
                <div className="wrapper">
                  <div className="keunggulan-accordion">
                    <div className="text">
                      <p>Keunggulan dari produk</p>
                      <h2>ASI Booster MamaBear</h2>
                    </div>
                    <div className="accordion" id="accordionExample">
                      <AccordionCustom
                        desc={`Komitmen atas kualitas adalah prinsip dibalik suksesnya Mamabear. Sejak awal, kami berprioritas pada bahan alami terbaik yang terbukti secara ilmiah meningkatkan produksi ASI. Kualitas kami juga disempurnakan dari segi teknologi, higienitas dan produk akhir.`}
                        title={"Kualitas Menyeluruh"}
                        target={"target2"}
                        show={true}
                        collapsed={true}
                      />

                      <AccordionCustom
                        desc={`Sebagai bukti komitmen kualitas pada solusi menyusui, setiap produk Mamabear sudah teruji dan tersertifikasi Halal & BPOM. Temukan informasi produk dan anjuran konsumsi untuk hasil terbaik.`}
                        title={"Bersertifikat Halal & BPOM"}
                        target={"target3"}
                      />
                      <AccordionCustom
                        desc={`Mamabear adalah 100% produk lokal. Kami bangga akan penggunaan bahan- bahan Nusantara yang padat nutrisi dan baik untuk kesehatan Anda.`}
                        title={"Bangga Sebagai Produk Lokal"}
                        target={"target4"}
                      />
                      <AccordionCustom
                        desc={`Nutrisi akan bekerja semakin maksimal jika didukung citarasa lezat. Melalui inovasi dan teknik produksi, kami mampu menghantarkan minuman dan makanan yang lezat, lengkap dengan seluruh kandungan nutrisinya.`}
                        title={"Rasa Lezat"}
                        target={"target5"}
                      />
                      <AccordionCustom
                        desc={`Bukan hanya sekedar jualan, Mamabear Indonesia adalah platform lengkap dengan nilai edukasi dan komunitas mama menyusui yang saling mendukung dan berbagi. Follow Mamabear dan bergabunglah dengan kami!`}
                        title={"Komunitas Mama Menyusui"}
                        target={"target6"}
                      />
                    </div>
                  </div>

                  <div className="keunggulan-image">
                    <img
                      className="d-none d-lg-block"
                      src="images/faq_image-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="penghargaan">
              <div className="container">
                <div className="penghargaan-inner">
                  <div className="wrapp-judul">
                    <p className="top">Penghargaan Kami</p>
                    <p className="bottom">
                      Produk kami telah memperoleh banyak penghargaan &
                      terpercaya
                    </p>
                  </div>
                  <div className="wrapp-img">
                    <img src={Bpom} alt="404" />
                    <img src={Halal} alt="404" />
                    <img src={TopBrand} alt="404" />
                    <img src={Brand} alt="404" />
                    <img src={Brand2} alt="404" />
                  </div>
                </div>
              </div>
            </div>

            <div className="mam-say">
              <div className="container">
                <img src={Logo} alt="404" className="logo" />
                <div className="mam-say-inner">
                  <div className="wrapp-judul">
                    <p className="text-coklat">WHAT</p>
                    <p className="text-pink">MAMA</p>
                    <p className="text-coklat">SAYS</p>
                  </div>
                  <div className="wrapp-card-bayi">
                    <CardBayi beforeImage={Bayi1} afterImage={Bayi2} />
                    <CardBayi beforeImage={Bayi3} afterImage={Bayi4} />
                    <CardBayi beforeImage={Bayi5} afterImage={Bayi6} />
                  </div>
                </div>
              </div>
            </div>
            <div className="networking">
              <div className="container">
                <div className="wrapper">
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            <span>List Reseller</span> MamaBear
                          </h5>
                          <svg
                            data-dismiss="modal"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.16185 3.75488C3.9486 3.75488 3.7256 3.82655 3.56285 3.98905C3.23743 4.31489 3.23743 4.86154 3.56285 5.18738L8.79727 10.4215L3.56285 15.6557C3.23743 15.9816 3.23743 16.5282 3.56285 16.854C3.88835 17.179 4.43535 17.179 4.76085 16.854L9.99518 11.6199L15.2295 16.854C15.555 17.179 16.102 17.179 16.4275 16.854C16.7529 16.5282 16.7529 15.9816 16.4275 15.6557L11.1931 10.4215L16.4275 5.18738C16.7529 4.86154 16.7529 4.31489 16.4275 3.98905C16.2648 3.82655 16.0417 3.75488 15.8285 3.75488C15.6153 3.75488 15.3924 3.82655 15.2295 3.98905L9.99518 9.22321L4.76085 3.98905C4.5981 3.82655 4.3751 3.75488 4.16185 3.75488Z"
                              fill="#191919"
                            />
                          </svg>
                        </div>
                        <div className="modal-body">
                          <div className="wrapper">
                            <DataTableComponent />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    data-toggle="modal"
                    data-target="#exampleModal"
                    className="images d-md-none"
                  >
                    <img src="images/maps.png" alt="" />
                  </div>

                  <div className="images dekstop d-none d-md-block">
                    <img
                      className="map-image"
                      onClick={() => setShowMaps(true)}
                      // onMouseEnter={() => setShowMaps(true)}
                      // onMouseOutCapture={() => setShowMaps(false)}
                      src="images/maps.png"
                      alt=""
                      style={{ opacity: showMaps ? "0.5" : "1" }}
                    />

                    <div
                      className="modal-content dekstop-list-maps"
                      style={{ display: showMaps ? "block" : "none" }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          <span>List Reseller</span> MamaBear
                        </h5>
                        <svg
                          onClick={() => setShowMaps(false)}
                          width="20"
                          height="21"
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.16185 3.75488C3.9486 3.75488 3.7256 3.82655 3.56285 3.98905C3.23743 4.31489 3.23743 4.86154 3.56285 5.18738L8.79727 10.4215L3.56285 15.6557C3.23743 15.9816 3.23743 16.5282 3.56285 16.854C3.88835 17.179 4.43535 17.179 4.76085 16.854L9.99518 11.6199L15.2295 16.854C15.555 17.179 16.102 17.179 16.4275 16.854C16.7529 16.5282 16.7529 15.9816 16.4275 15.6557L11.1931 10.4215L16.4275 5.18738C16.7529 4.86154 16.7529 4.31489 16.4275 3.98905C16.2648 3.82655 16.0417 3.75488 15.8285 3.75488C15.6153 3.75488 15.3924 3.82655 15.2295 3.98905L9.99518 9.22321L4.76085 3.98905C4.5981 3.82655 4.3751 3.75488 4.16185 3.75488Z"
                            fill="#191919"
                          />
                        </svg>
                      </div>
                      <div className="modal-body">
                        <div className="wrapper">
                          <DataTableComponent />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text">
                    <h2>
                      Jaringan Luas <br /> Reseller <span>MamaBear</span>
                    </h2>
                    <p>
                      Sejak tahun 2019, Mamabear telah membantu lebih dari
                      500.000 ibu menyusui dengan lebih dari 500 agen yang
                      tersebar di Indonesia dan Filipina.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </PrimeReactProvider>
  );
}
